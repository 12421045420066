 footer{
    .info{
        &:before{
            @include sizesbl($width: 935px, $height: 1px, $bottom: 0, $left: 50%);
            transform: translateX(-50%);
            background-color: $cyan;
        }
    }
    nav{
        a{
            color: $blue3;
            
            &:hover{
                color: $blue;
            }
        }
    }
    .copy{
        font-size: 13px;
    }
}

@media(max-width: 992px){
    footer {
        .info:before{
            width: 100%;
        }
        .social{
            width: 100%;
            text-align: center;
        }
    }
}