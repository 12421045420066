body {
    font-family: 'Montserrat', sans-serif;
}

@mixin trans {
    opacity: 0;
    transition: .25s ease-in-out;
}

@mixin sizestl($width, $height, $top, $left) {
    width: $width;
    height: $height;
    top: $top;
    left: $left;
}

@mixin sizesbr($width, $height, $bottom, $right) {
    width: $width;
    height: $height;
    bottom: $bottom;
    right: $right;
}

@mixin sizestr($width, $height, $top, $right) {
    width: $width;
    height: $height;
    top: $top;
    right: $right;
}

@mixin sizesbl($width, $height, $bottom, $left) {
    width: $width;
    height: $height;
    bottom: $bottom;
    left: $left;
}

a {
    transition: .25s ease-in-out;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

h2 {
    &:before {
        background-color: $cyan;
    }
}

p,
p.h3,
p.h4,
p.h5,
p.h6 {
    line-height: 1.5;
}

.container-fluid {
    max-width: 1320px;
}

.btn {
    padding: 14px 39px;
}

.btn-light {
    background-color: $white;
    color: $blue;

    &:hover,
    &:focus,
    &:active {
        background-color: $blue !important;
        color: $white !important;
        border-color: $blue !important;
    }
}

.btn-outline-primary {
    &:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    }
}

.fs-14 {
    font-size: 14px;
}

.fs-18 {
    font-size: 18px;
}

path,
circle,
stroke,
img {
    transition: .25s ease-in-out;
}

:active,
:hover,
:focus,
button:focus {
    outline: 0;
    outline-offset: 0;
}

.bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-start {
    position: relative;

    &:before,
    &:after {
        position: absolute;
        content: '';
    }
}

.bg-before {
    position: relative;

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

input,
textarea,
.choose {
    padding-top: 12px;
    padding-bottom: 12px;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(78, 172, 239, 0.7);
}

label.error {
    display: none !important;
}

input.error,
textarea.error {
    border: 1px solid $red;
}

textarea {
    min-height: 121px;
}

.ui-autocomplete-input {
    position: relative;
    padding: 12px 24px;
    width: 100%;
    min-height: 49px;
}

.ui-autocomplete {
    top: 47px;
    padding: 11px !important;
    background-color: $blue;
    color: $white;
    padding: 0;
    list-style: none;
    max-height: 352px;
    overflow-x: hidden !important;
}

.drop {
    .choose {
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.7627 8.80078L8.83376 15.8718L15.9048 8.80078' stroke='%234EACEF' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: 97% 43%;
        cursor: pointer;
    }

    .drop-menu {
        position: absolute;
        top: 47px;
        background-color: $blue;
        width: 100%;
        display: none;
        z-index: 2;

        &.show {
            display: none;
        }

        p {
            margin-bottom: 4px;
            padding: 10px 24px;
            color: $white;
            cursor: pointer
        }
    }

    &.active {
        .choose {
            color: $black;
        }
    }
}

img {
    opacity: 1;
    transition: opacity 0.3s;
}

img[data-src] {
    opacity: 0;
}
