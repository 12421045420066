.about-page {
    .team-block {
        h2 {
            color: $blue !important;
        }

        p {
            color: $gray-900 !important;
        }
    }
}

.about-us-block {

    &:before {
        @include sizesbr($width: 890px, $height: 290px, $bottom: -50px, $right: 100px);
        background-image: url(../img/bg_decor.png);
    }

    h2 {
        &:before {
            @include sizesbl($width: 122px, $height: 2px, $bottom: -30px, $left: -61px);
        }
    }

    .img-wrap {
        &:before {
            @include sizestl($width: 2px, $height: 122px, $top: 45%, $left: 18px);
            background-color: $cyan;
            z-index: 2;
        }

        &:after {
            @include sizesbr($width: 122px, $height: 2px, $bottom: 30px, $right: -55px);
            background-color: $cyan;
            z-index: 2;
        }

        .img-1 {
            max-width: 65%;
        }

        .img-2 {
            bottom: 50px;
            left: 0;
        }
    }

    .col-xl-7 {
        margin-top: -110px;
        &:before {
            @include sizestr($width: 170px, $height: 230px, $top: -50px, $right: -35px);
            background-color: rgba($cyan, .8);
            z-index: 2;
        }
    }
}

.about-info {
    .container {
        padding-top: 70px;        
        background-size: 873px 597px;
        background-repeat: no-repeat;

        &:before {
            bottom: -25px;
            left: -40px;
            width: 190px;
            height: 255px;
            background-color: rgba($cyan, .8);
        }
    }

    &:before {
        @include sizesbl($width: 820px, $height: 290px, $bottom: 0, $left: 0);
        background-image: url(../img/bg_decor.png);
    }

    h2 {
        font-size: 35px;
    }

    .block {
        padding: 50px 95px;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
        max-width: 730px;
    }
}

.why-block {
    h2 {
        &:before {
            @include sizestl($width: 122px, $height: 2px, $top: -30px, $left: 61%);
        }
    }

    .text {
        padding: 60px;
        max-width: 850px;
        z-index: 2;
        box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.1);
    }

    .item-1 {
        margin-bottom: 80px;

        .img-wrap {
            margin-top: -110px;
        }
    }

    .item-2 {
        margin-top: -250px;
        margin-bottom: 80px;

        .text {
            margin-top: -120px;
        }
    }

    img {
        max-width: 500px;
    }
}

.document-block {
    p {
        font-size: 22px;
        max-width: 250px;
    }
}

/*---------*/

.centers-page {
    .centers-block {
        background-color: $blue;

        h2,
        p {
            color: $white !important;

            &:before {
                background-color: $white;
            }
        }
    }

    .about-info {
        &:before {
            top: -50px;
            right: 0;
            left: auto;
        }

        .container {
            background-position-x: right;

            &:before {
                left: auto;
                right: -40px;
            }
        }
    }
}

.centers-info {
    &:before {
        @include sizestl($width: 460px, $height: 240px, $top: -85px, $left: 0);
        background-image: url(../img/bg_decor.png);
    }

    h2 {
        &:before {
            @include sizesbl($width: 122px, $height: 2px, $bottom: -30px, $left: -40px);
        }
    }

    .img-wrap {
        &:before {
            @include sizestl($width: 122px, $height: 2px, $top: 30px, $left: -40px);
            background-color: $cyan;
        }

        &:after {
            @include sizesbr($width: 2px, $height: 122px, $bottom: -50px, $right: 150px);
            background-color: $cyan;
        }
    }
}

.blog-page {
    main {
        &:before {
            @include sizestl($width: 820px, $height: 290px, $top: 220px, $left: 0);
            background-image: url(../img/bg_decor.png);
        }

        &:after {
            @include sizesbr($width: 810px, $height: 100px, $bottom: 0px, $right: 0);
            background-image: url(../img/bg_decor.png);
        }
    }

    .form-wrap {
        max-width: 990px;
    }
}

.blog-favorite {
    h1 {
        &:before {
            @include sizesbl($width: 122px, $height: 2px, $bottom: -30px, $left: -61px);
            background-color: $cyan;
        }
    }

    .item {
        &:hover {
            .img {
                transform: scale(1.15);
            }
        }

        &:before {
            @include sizesbl($width: 2px, $height: 122px, $bottom: -45px, $left: 20px);
            background-color: $cyan;
            z-index: 2;
        }

        .text {
            left: 60px;
            bottom: 30px;
        }
    }


    .item-s {
        &:hover {
            img {
                transform: scale(1.2);
            }
        }
    }

    .img {
        &:before {
            @include sizestl($width: 100%, $height: 100%, $top: 0, $left: 0);
            background: linear-gradient(180deg, rgba(7, 54, 88, 0) 50%, #073658 100%);
        }

        transition: .25s ease-in-out;
    }

    .img-wrap {
        &:before {
            @include sizestl($width: 100%, $height: 100%, $top: 0, $left: 0);
            background: linear-gradient(180deg, rgba(7, 54, 88, 0) 65.4%, #073658 100%);
            z-index: 2;
        }

        min-width: 200px;

        img {
            transition: .25s ease-in-out;
        }
    }
}

.choice-block {
    .text {
        &:before {
            @include sizestl($width: 2px, $height: 100%, $top: 0, $left: 0);
            background-color: $cyan;
        }
    }

    img {
        transition: .25s ease-in-out;
    }

    .item {
        &:hover {
            img {
                transform: scale(1.2);
            }
        }
    }
}

.page-link {
    font-weight: 600;

    &.active {
        background-color: $blue;
        color: $white;
    }
}

.blog-inside-page {
    main {
        &:before {
            @include sizesbr($width: 810px, $height: 100px, $bottom: 0px, $right: 0);
            background-image: url(../img/bg_decor.png);
        }
    }

    .blog-block {
        &:before {
            display: none;
        }
    }
}

.blog-inside-main {
    min-height: 700px;

    &:before {
        @include sizestl($width: 100%, $height: 100%, $top: 0, $left: 0);
        background: linear-gradient(180deg, #073658 0%, rgba(7, 54, 88, 0) 51.27%, rgba(7, 54, 88, 0.4) 69.26%, #073658 100%);
    }
}

.blog-info {
    &:before {
        @include sizestl($width: 720px, $height: 190px, $top: 0, $left: 0);
        background-image: url(../img/bg_decor.png);
    }

    p {
        color: $gray-800;
    }

    h3 {
        color: $blue3;
    }

    .btn {
        &:hover {
            path {
                stroke: $white;
            }
        }
    }

    .text {
        &:before {
            @include sizestl($width: 2px, $height: 100%, $top: 0, $left: 0);
            background-color: $cyan;
        }
    }
}

@media(max-width: 1200px) {
    .about-us-block {
        padding-top: 70px;
        margin-top: 0;

        .col-xl-7 {
            &:before {
                right: -20px;
            }
        }

        .img-wrap:after {
            right: -30px;
        }

        .img-2 {
            max-width: 50%;
        }
    }

    .about-info {
        .container {
            padding-top: 500px;

            &:before {
                bottom: 300px;
            }
        }

        .block {
            max-width: 850px;
        }
    }

    .why-block {
        .text {
            max-width: 750px;
        }

        .item-1 {
            margin-bottom: 130px;
        }

        img {
            max-width: 45%;
        }
    }

    .centers-page .about-info .container:before {
        right: -20px;
        bottom: 350px;
    }

    .blog-favorite {
        .item {
            min-height: 482px;
        }
    }
}

@media(max-width: 992px) {
    .about-us-block {
        h2 {
            &:before {
                left: 30%;
            }
        }
        
        .col-xl-7{
            margin-top: 0;
        }
    }

    .about-info {
        &:before {
            width: 100%;
        }

        .container {
            padding-top: 450px;
            background-size: 100% 540px;

            &:before {
                bottom: 380px;
                width: 120px;
            }
        }

        .block {
            padding: 50px 20px;
        }
    }

    .why-block {
        .text {
            max-width: 660px;
        }

        .item-1 {
            margin-bottom: 300px;

            .img-wrap {
                margin-top: -40px;
            }
        }

        .item-2 {
            .text {
                margin-top: -40px;
            }
        }

        img {
            max-width: 60%;
        }
    }

    .blog-page {
        main {
            &:before {
                width: 100%;
            }

            &:after {
                width: 100%;
            }
        }
    }

    .blog-favorite {
        img {
            width: 100%;
            max-height: 264px;
        }

        .text {
            &:before {
                @include sizestl($width: 2px, $height: 100%, $top: 0, $left: 0);
                background-color: $cyan;
            }
        }
    }

    .blog-inside-page {
        main {
            &:before {
                width: 90%;
            }
        }
    }

    .blog-info {
        &:before {
            width: 90%;
        }
        .col-lg-4{
            max-width: 450px;
        }
    }
}

@media(max-width: 767px) {
    .why-block {
        .text {
            padding: 30px 20px;
        }

        img {
            max-width: 100%;
        }
    }

    .about-info {
        .container {
            padding-top: 250px;
            background-size: 100% 350px;
        }

        h2 {
            font-size: 25px;
        }
    }

    .centers-page .about-info .container:before {
        right: 0px;
        bottom: 450px;
    }

    .centers-info {
        &:before {
            width: 100%;
            height: 200px;
            top: -100px;
        }

        .img-wrap {
            &:after {
                right: 30px;
            }
        }
    }

    .blog-favorite {
        .item {
            min-height: 363px;
        }
    }
    
    .blog-inside-main{
        padding-bottom: 50px;
        min-height: 100vh;
    }
}

@media(max-width: 576px) {
    .why-block {
        .text {
            margin-left: auto !important;
            margin-right: auto !important;
            max-width: 96%;
        }
    }

    .about-us-block {
        .col-xl-7 {
            &:before {
                width: 100px;
                height: 140px;
                right: 5px;
            }
        }

        .img-wrap {
            &:after {
                right: -10px;
            }

            &:before {
                top: 35%;
            }
        }
    }
}
