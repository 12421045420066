header {
    position: absolute;
    z-index: 999;

    .tel {
        color: $white;
    }

    .social {
        svg {
            &:hover {
                path {
                    fill: $blue;
                }
            }
        }
    }

    .lang {
        color: $white;
        cursor: pointer;

        .wrap {
            &:hover {
                span {
                    color: $blue;
                }

                path {
                    stroke: $blue;
                }
            }
        }

        span {
            transition: .25s ease-in-out;
            min-width: 30px;
        }

        svg {
            margin-top: -8px;
        }

        ul {
            left: -8px;
            top: 25px;
            background: $blue;
            display: none;
            width: calc(100% + 16px);

            &.show {
                display: block;
            }
        }

        li {
            margin-bottom: 4px;

            &:hover {
                text-shadow: 0 0 0.7px $white, 0 0 0.7px $white;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .menu {
        padding: 12px 0;
        background-color: rgba($white, .9);
        z-index: 9999;
        transition: .25s ease-in-out;

        &.menu-down {
            transform: translate3d(0, 0, 0);
            position: fixed;
            top: 0;
        }

        &.menu-up {
            transform: translate3d(0, -100%, 0);
        }
    }

    nav {
        a {
            color: $gray-900;
            font-size: .9rem;
        }
    }

    li.current {
        a {
            color: $blue;
        }
    }

    a:hover {
        color: $blue;
    }

    .btn {
        font-size: .9rem;
    }
}

.blog-page {
    header {
        position: relative !important;

        .tel,
        .lang {
            color: $gray-900;
            &:hover{
                color: $blue;
            }
            path{
                stroke: $gray-900;
            }
            li{
                color: $white;
            }
        }

        .social {
            path {
                fill: $gray-900;
            }
        }
    }
}

.nav-icon-5 {
    width: 25px;
    height: 20px;
    position: relative;
    cursor: pointer;
    display: inline-block;
}

.nav-icon-5 span {
    background-color: $blue;
    position: absolute;
    border-radius: 2px;
    transition: .3s cubic-bezier(.8, .5, .2, 1.4);
    width: 100%;
    height: 3px;
    transition-duration: 500ms
}

.nav-icon-5 span:nth-child(1) {
    top: 0px;
    left: 0px;
}

.nav-icon-5 span:nth-child(2) {
    top: 8px;
    left: 0px;
    opacity: 1;
}

.nav-icon-5 span:nth-child(3) {
    bottom: 1px;
    left: 0px;
}

.nav-icon-5.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 13px;
}

.nav-icon-5.open span:nth-child(2) {
    opacity: 0;
}

.nav-icon-5.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 13px;
}

.mm-navbar {
    display: none;
}

.mm-menu {
    padding-top: 50px;
    padding-left: 50px;
}

.mm-listitem__text {
    white-space: normal;
}

.mm-listitem {

    a {
        color: $black;

        &.current {
            color: $blue;
        }
    }

    &:after {
        display: none;
    }
}

.mm-sticky.mh-head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    transition: transform .2s ease;
    transform: translate3d(0, 0, 0);
}

.mm-sticky.mh-head.mh-scrolledout {
    transform: translate3d(0, -100%, 0);
}

#menu {
    opacity: 0;

    &.mm-menu_opened {
        opacity: 1;
    }

    .btn {
        margin-left: 20px;
        max-width: 220px;
    }
}

@media(max-width: 370px) {
    .mm-menu {
        padding-top: 30px;
        padding-left: 15px;
    }
}
