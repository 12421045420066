.home-page {
    .team-block {
        background-color: $white;

        h2 {
            &:before {
                background-color: $white;
            }
        }

        .bg {
            width: 100%;
            height: 715px;
            top: 0;
            left: 0;
            background-color: $blue;
        }
    }
}

.first-screen {
    padding: 30vh 0 20vh;

    p {
        max-width: 720px;
    }
}

.services-block {
    margin-top: -86px;

    svg {
        min-width: 48px;
    }

    span {
        min-height: 54px;
        font-size: 13px;
    }

    .col-xl-2 {
        background-color: rgba($black, 0.2);
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba($white, .2);
        transition: .25s ease-in-out;

        &:last-child {
            border: 0;
        }

        @media(min-width: 1200px) {
            &:hover {
                background-color: rgba($cyan, .8);

                path {
                    fill: $white;
                }
            }
        }
    }

    .slick-list {
        margin: auto;
        max-width: 95%;
    }

    .slick-prev {
        left: -15px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 492 492' width='20'%3E%3Cpath fill='%230070C0' d='M464.344 207.418l.768.168H135.888l103.496-103.724c5.068-5.064 7.848-11.924 7.848-19.124 0-7.2-2.78-14.012-7.848-19.088L223.28 49.538c-5.064-5.064-11.812-7.864-19.008-7.864-7.2 0-13.952 2.78-19.016 7.844L7.844 226.914C2.76 231.998-.02 238.77 0 245.974c-.02 7.244 2.76 14.02 7.844 19.096l177.412 177.412c5.064 5.06 11.812 7.844 19.016 7.844 7.196 0 13.944-2.788 19.008-7.844l16.104-16.112c5.068-5.056 7.848-11.808 7.848-19.008 0-7.196-2.78-13.592-7.848-18.652L134.72 284.406h329.992c14.828 0 27.288-12.78 27.288-27.6v-22.788c0-14.82-12.828-26.6-27.656-26.6z'/%3E%3C/svg%3E");

        &:before {
            content: '';
        }

    }

    .slick-next {
        right: -15px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 492.004 492.004' width='20'%3E%3Cpath fill='%230070C0' d='M484.14 226.886L306.46 49.202c-5.072-5.072-11.832-7.856-19.04-7.856-7.216 0-13.972 2.788-19.044 7.856l-16.132 16.136c-5.068 5.064-7.86 11.828-7.86 19.04 0 7.208 2.792 14.2 7.86 19.264L355.9 207.526H26.58C11.732 207.526 0 219.15 0 234.002v22.812c0 14.852 11.732 27.648 26.58 27.648h330.496L252.248 388.926c-5.068 5.072-7.86 11.652-7.86 18.864 0 7.204 2.792 13.88 7.86 18.948l16.132 16.084c5.072 5.072 11.828 7.836 19.044 7.836 7.208 0 13.968-2.8 19.04-7.872l177.68-177.68c5.084-5.088 7.88-11.88 7.86-19.1.016-7.244-2.776-14.04-7.864-19.12z'/%3E%3C/svg%3E");

        &:before {
            content: '';
        }
    }

    .slick-arrow {
        &.slick-disabled {
            display: none !important;
        }
    }
}

.about-block {
    background-image: url(../img/bg_decor.png);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 800px 210px;

    h2 {
        &:before {
            @include sizesbl($width: 122px, $height: 2px, $bottom: -30px, $left: -61px);
        }
    }

    .img-wrap {
        &:before {
            @include sizestl($width: 2px, $height: 122px, $top: -80px, $left: 18px);
            background-color: $cyan;
        }

        &:after {
            @include sizesbr($width: 122px, $height: 2px, $bottom: 30px, $right: -30px);
            background-color: $cyan;
        }
    }

    .col-md-12 {
        &:before {
            @include sizestr($width: 135px, $height: 145px, $top: -30px, $right: -35px);
            background-color: rgba($cyan, .8);
            z-index: 2;
        }
    }

    img.position-absolute {
        top: -60px;
        right: 85px;
        max-width: 80%;
    }
}

.centers-block {
    h2 {
        &:before {
            @include sizestl($width: 122px, $height: 2px, $top: -25px, $left: 30%);
        }
    }

    p {
        max-width: 900px;
    }

    .item {
        &:before {
            @include sizesbl($width: 2px, $height: 80px, $bottom: -18px, $left: 15px);
            background-color: $cyan;
            transition: .25s ease-in-out;
            z-index: 3;
        }

        &:after {
            @include sizestl($width: 100%, $height: 100%, $top: 0, $left: 0);
            background: linear-gradient(0deg, rgba(78, 172, 239, 0.8), rgba(78, 172, 239, 0.8));
            box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
            @include trans;
        }

        &:hover {
            overflow: hidden;

            &:before {
                bottom: 150px;
                background-color: $white;
            }

            &:after {
                opacity: 1;
            }

            .text {
                bottom: auto;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 3;
            }

            .info {
                opacity: 1;
                display: block;
            }
        }
    }

    .text {
        top: 80%;
        left: 50%;
        transform: translateX(-50%);
        transition: .25s ease-in-out;
    }

    .info {
        @include trans;
        display: none;
    }

    .btn {
        padding: 8px 22px;

        &:hover,
        &:active,
        &:focus {
            background: $blue !important;
            border-color: $blue !important;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25) !important;
        }
    }
}

.membership-block {
    padding: 180px 0 50px;
    background-size: 633px 487px;
    background-position: 30% 60px;
    background-repeat: no-repeat;

    &:before {
        top: 0;
        left: 30%;
        transform: rotateX(-50%);
        width: 890px;
        height: 290px;
        background-image: url(../img/bg_decor.png);
    }

    &:after {
        width: 120px;
        height: 180px;
        left: 19%;
        top: 390px;
        background-color: rgba($cyan, .8);
    }

    .block {
        padding: 50px 95px;
        max-width: 700px;
        background-color: rgba($white, .9);
    }

    .btn {
        &:hover {
            background: $cyan;
            border-color: $cyan;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
        }
    }
}

.plane-block {
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
        bottom: -75%;
        right: 0;
        width: 345px;
        height: 290px;
        background-image: url(../img/bg_decor.png);
    }

    .container {
        &:before {
            width: 70px;
            height: 50px;
            top: -100px;
            left: 5%;
            background-size: 70px 50px;
            background-image: url("data:image/svg+xml,%3Csvg width='124' height='92' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M40.266 42.003c-3.033-.777-6.066-1.17-9.017-1.17-4.556 0-8.357.925-11.305 2.059 2.842-9.25 9.669-25.21 23.269-27.008 1.259-.166 2.291-.976 2.635-2.066l2.972-9.452c.25-.8.102-1.655-.41-2.348-.513-.694-1.338-1.157-2.261-1.27a25.35 25.35 0 00-3.041-.183c-16.324 0-32.49 15.148-39.313 36.838C-.209 50.128-1.384 69.259 8.48 81.301 14 88.039 22.053 91.637 32.416 91.997l.127.002c12.785 0 24.123-7.656 27.572-18.616 2.06-6.552 1.13-13.423-2.624-19.352-3.714-5.863-9.83-10.137-17.225-12.028zM120.304 54.032c-3.714-5.864-9.831-10.138-17.225-12.03-3.033-.776-6.066-1.17-9.015-1.17-4.556 0-8.36.926-11.307 2.06 2.842-9.25 9.669-25.21 23.27-27.008 1.26-.166 2.29-.976 2.635-2.066l2.973-9.452c.25-.8.102-1.655-.411-2.348-.512-.694-1.336-1.157-2.261-1.27a25.307 25.307 0 00-3.041-.183c-16.324 0-32.49 15.148-39.313 36.838-4.004 12.725-5.178 31.856 4.687 43.9C76.815 88.04 84.87 91.64 95.23 91.998l.128.002c12.785 0 24.124-7.656 27.573-18.615 2.058-6.553 1.126-13.425-2.627-19.353z' fill='%234EACEF'/%3E%3C/svg%3E");
            opacity: .2;
        }

        &:after {
            width: 70px;
            height: 50px;
            bottom: -95px;
            right: 5%;
            background-size: 70px 50px;
            background-image: url("data:image/svg+xml,%3Csvg width='125' height='93' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M84.864 50.535c3.058.782 6.117 1.178 9.092 1.178 4.595 0 8.429-.931 11.401-2.072-2.866 9.307-9.75 25.366-23.465 27.174-1.27.168-2.31.982-2.657 2.08l-2.998 9.51a2.616 2.616 0 00.415 2.362c.517.698 1.348 1.164 2.28 1.277a25.64 25.64 0 003.066.185c16.462 0 32.765-15.242 39.644-37.066 4.038-12.803 5.222-32.052-4.725-44.168C111.351 4.215 103.23.595 92.78.233c-.043-.001-.085-.003-.128-.003-12.893 0-24.327 7.703-27.805 18.73-2.078 6.593-1.138 13.507 2.647 19.473 3.745 5.9 9.913 10.2 17.37 12.102zM4.338 38.432c3.745 5.9 9.913 10.2 17.37 12.103 3.058.782 6.117 1.178 9.091 1.178 4.595 0 8.43-.931 11.402-2.072-2.866 9.307-9.75 25.366-23.466 27.174-1.27.168-2.31.982-2.657 2.08l-2.998 9.51a2.616 2.616 0 00.414 2.362c.516.698 1.347 1.164 2.28 1.277 1.01.123 2.043.185 3.067.185 16.461 0 32.764-15.242 39.645-37.066 4.037-12.803 5.221-32.052-4.727-44.17C48.192 4.212 40.07.592 29.622.231c-.043-.002-.085-.003-.13-.003C16.602.23 5.167 7.932 1.689 18.96-.387 25.553.554 32.468 4.338 38.433z' fill='%234EACEF'/%3E%3C/svg%3E");
            opacity: .2;
        }
    }

    h2 {
        max-width: 890px;
        font-size: 25px;
    }

    p {
        max-width: 415px;
    }
}

.us-block {

    .container {
        max-width: 960px;
    }

    &:before {
        @include sizestl($width: 890px, $height: 290px, $top: 52%, $left: 2%);
        background-image: url(../img/bg_decor.png);
    }


    h2 {
        &:before {
            @include sizesbl($width: 122px, $height: 2px, $bottom: -20px, $left: -61px);
        }
    }

    p {
        margin-top: -70px;
        margin-left: 90px;
        position: relative;
        max-width: 280px;
        font-size: 18px;
    }

    img {
        max-width: 280px;
        transition: .25s ease-in-out;
    }

    .item {
        overflow: hidden;

        &:hover {
            img {
                transform: scale(1.2);
            }
        }
    }
}

.team-block {
    &:before {
        @include sizestr($width: 890px, $height: 290px, $top: 24%, $right: 0);
        background-image: url(../img/bg_decor.png);
    }

    .container-fluid {
        max-width: 100%;
    }

    h2 {
        &:before {
            @include sizestr($width: 122px, $height: 2px, $top: -25px, $right: -60px);
        }
    }

    p {
        max-width: 920px;
    }

    .item {

        p {
            max-width: 250px;
        }
    }

    .slider {
        opacity: 0;
    }

    .slick-slide {
        margin: 0 16px;
    }

    .slick-dots {
        position: relative;

        li {
            width: 65px;
            height: 3px;
            background-color: rgba($cyan, .5);

            &.slick-active {
                top: -1.5px;
                height: 6px;
                width: 130px;
                background-color: $cyan;
            }

            button {
                &:before {
                    display: none;
                }
            }
        }
    }

    .img-wrap {
        margin-left: auto;
        margin-right: auto;
        max-width: 280px;

        &:before {
            @include sizesbl($width: 2px, $height: 122px, $bottom: -18px, $left: 30px);
            background-color: $cyan;
            transition: .25s ease-in-out;
            z-index: 3;
        }
    }
}

.year-block {
    .year {
        font-size: 80px;
        line-height: 0.4;
        font-family: 'Abhaya Libre', serif;
        max-width: 160px;

        &:before {
            @include sizestl($width: 155px, $height: 155px, $top: -65px, $left: 15px);
            background-color: rgba($cyan, .7);
            z-index: 0;
        }

        &:after {
            @include sizestl($width: 155px, $height: 155px, $top: -35px, $left: -20px);
            background-color: rgba($cyan, .7);
            z-index: 0;
        }

        span {
            z-index: 1;
        }
    }

    img {
        max-width: 140px;
    }
}

.blog-block {
    &:before {
        @include sizestl($width: 840px, $height: 290px, $top: 253px, $left: 0);
        background-image: url(../img/bg_decor.png);
    }

    h2 {
        &:before {
            @include sizesbl($width: 122px, $height: 2px, $bottom: -30px, $left: -60px);
        }
    }

    .item {
        &:before {
            @include sizesbl($width: 2px, $height: 122px, $bottom: -18px, $left: 30px);
            background-color: $cyan;
            transition: .25s ease-in-out;
            z-index: 3;
        }

        &:after {
            @include sizestl($width: 100%, $height: 100%, $top: 0, $left: 0);
            background: linear-gradient(0deg, rgba(78, 172, 239, 0.8), rgba(78, 172, 239, 0.8));
            box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
            @include trans;
        }

        @media(min-width: 1200px) {
            &:hover {
                overflow: hidden;

                &:before {
                    bottom: 40%;
                    background-color: $white;
                }

                &:after {
                    opacity: 1;
                }

                .text {
                    bottom: auto;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 3;
                }

                .text .text-center {
                    opacity: 1;
                    display: block;
                }
            }
        }

        .text .text-center {
            @include trans;
            display: none;
        }
    }

    .btn {

        &:hover,
        &:active,
        &:focus {
            background: $blue !important;
            border-color: $blue !important;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25) !important;
        }
    }

    .img {
        min-height: 450px;
        background-repeat: no-repeat;
        background-size: cover;
        &:before{
            @include sizestl($width: 100%, $height: 100%, $top: 0, $left: 0);
            background: linear-gradient(180deg, rgba(7, 54, 88, 0) 50%, #073658 100%), url(image.png);
        }
    }

    .text {
        top: 70%;
        left: 50%;
        transform: translateX(-50%);
        transition: .25s ease-in-out;
        width: 85%;
    }

    .subscribe-form {
        max-width: 640px;
    }
}

.contact-block {
    &:before {
        @include sizesbr($width: 810px, $height: 100px, $bottom: 0, $right: 0);
        background-image: url(../img/bg_decor.png);
    }

    h2 {
        &:before {
            @include sizestl($width: 122px, $height: 2px, $top: -15px, $left: 60%);
        }
    }

    form {
        max-width: 960px;
    }
}


@media(min-width: 1200px) {
    .centers-block {
        .col-xl {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
}

@media(max-width: 1400px) {
    h1 {
        font-size: 50px;
    }

    h2 {
        font-size: 40px;
    }

    .first-screen {
        padding-bottom: 130px;
    }

    .home-page {
        .team-block {
            .bg {
                height: 581.5px;
            }
        }
    }
}

@media(max-width: 1200px) {
    .home-page {
        .team-block {
            .bg {
                height: 703px;
            }
        }
    }

    .first-screen {
        padding: 20vh 0 15vh;
        min-height: 60vh;
    }

    .services-block {

        .col-xl-2 {

            &:nth-child(4),
            &:nth-child(5),
            &:last-child {
                background-color: rgba($cyan, .8);

                path {
                    fill: $white;
                    stroke: $white;
                }
            }
        }
    }

    .about-block {
        .col-md-12 {
            &:before {
                width: 120px;
                right: -15px;
            }
        }

        h2 {
            &:before {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .centers-block {

        img {
            width: 100%;
        }

        .item {
            &:after {
                opacity: .3;
            }

            &:hover {
                &:before {
                    bottom: -18px;
                    background-color: $cyan;
                }

                &:after {
                    opacity: .3;
                }

                .text {
                    bottom: auto;
                    top: 50%;
                    z-index: 3;
                    transform: translate(-50%, 0);
                }
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            bottom: auto;
            top: 50%;
            z-index: 3;
        }

        .info {
            margin-bottom: 30px;
            opacity: 1;
            display: block;
            order: 1;
        }

        h3 {
            order: 2;
        }

        address {
            display: none;
        }
    }

    .membership-block {
        padding-top: 500px;
        background-size: calc(100% - 90px) 645px;

        &:before {
            display: none;
        }

        &:after {
            top: 0;
            left: 10px;
        }

        .block {
            max-width: 800px;
        }
    }

    .plane-block {
        .container {
            &:before {
                left: 2%;
            }

            &:after {
                right: 2%;
            }
        }
    }

    .team-block {
        .slick-dots {

            li {
                width: 40px;

                &.slick-active {
                    width: 80px;
                }
            }
        }
    }

    .blog-block {
        .item {
            &:before {
                left: 15px;
                bottom: 40%;
                background-color: $white;
            }

            &:after {
                opacity: .25;
            }

            .text {
                bottom: auto;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 3;
            }

            .text .text-center {
                opacity: 1;
                display: block;
            }
        }

        .text {
            top: 60%;
            width: 100%;
        }

        .img {
            min-height: 400px;
        }
    }
}

@media(max-width: 992px) {

    .home-page {
        .team-block {
            .bg {
                height: 589.25px;
            }
        }
        
        .first-screen {
            min-height: 100vh;
        }
    }

    .first-screen {
        padding-top: 30vh;
        min-height: 80vh;
    }

    .about-block {
        background-size: 50% 210px;
        background-position-y: 500px;
    }

    .us-block {
        &:before {
            width: 70%;
        }

        p {
            margin-top: -40px;
        }
    }

    .blog-block {
        &:before {
            width: 70%;
        }
    }

    .team-block {
        h3 {
            font-size: 25px;
        }

        .slider {
            p {
                font-size: 22px;
            }
        }
    }

    .blog-block {
        .item {
            margin: auto;
            max-width: 70%;
        }
    }

    .contact-block {
        &:before {
            width: 100%;
        }
    }
}

@media(max-width: 767px) {
    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 35px;
    }

    .first-screen {
        padding: 35vh 0 40vh;
    }

    .about-block {
        h2 {
            &:before {
                left: 30%;
            }
        }
        
        .img-wrap{
            &:after{
                right: -10px;
            }
        }

        img.position-absolute {
            max-width: 40%;
        }
        .col-md-12{
            &:before{
                width: 80px;
                height: 100px;
                right: 20px;
            }
        }
    }

    .membership-block {
        padding-top: 380px;
        background-position: left top;
        background-size: 100% 450px;

        &:after {
            height: 105px;
            width: 50px;
            left: 0;
            top: -20px;
        }

        .block {
            padding: 30px 10px;
        }
    }

    .plane-block {
        &:before {
            top: auto;
            bottom: -170px;
            width: 40%;
        }
    }

    .us-block p {
        margin-top: -30px;
        margin-left: 70px;
    }

    .team-block {
        .slick-dots {

            li {
                width: 15px;

                &.slick-active {
                    width: 20px;
                    height: 4px;
                    top: -0.5px;
                }
            }
        }
    }

    .blog-block {
        .img {
            min-height: 400px;
        }

        h3 {
            font-size: 25px;
        }
    }


}

@media(max-width: 576px) {
    .home-page {
        .first-screen {
            min-height: 100vh;
        }
    }

    .first-screen {
        padding: 25vh 0 20vh;
        min-height: auto;
    }

    .services-block {
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;

        .col-xl-2 {

            &:nth-child(4),
            &:nth-child(3) {
                background-color: rgba($cyan, .8);

                path {
                    fill: $white;
                    stroke: $white;
                }
            }
        }
    }

    .blog-block {
        .item {
            max-width: 100%;
        }
    }

    #jarallax-container-0 div {
        background-position: 65% 50% !important;
    }
}

@media(max-width: 400px) {
    .home-page {
        .team-block {
            .bg {
                height: 699.4px;
            }
        }
    }

    .centers-block {
        .item {
            &:before {
                left: 5px;
            }
        }

        .text {
            padding: 0 12px !important;
        }

        .info {
            margin-bottom: 15px;
        }

        h3 {
            font-size: 16px;
        }
    }
}

@media(max-width: 370px) {
    .home-page {
        .team-block {
            .bg {
                height: 682px;
            }
        }
    }
}

@media(max-width: 350px) {
    .home-page {
        .team-block {
            .bg {
                height: 662.7px;
            }
        }
    }

    .centers-block {
        .col-6 {
            padding: 0 8px;
        }
    }
}
